import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, from } from 'rxjs';
import { UserService } from './core/services/user/user.service';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest, BrowserAuthError } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  private readonly translate = inject(TranslateService);
  private readonly userService = inject(UserService);
  private readonly authService = inject(MsalService);
  private initializationPromise: Promise<void> | null = null;
  private initialized = false;

  async initializeApp(): Promise<void> {
    if (this.initialized) {
      return;
    }

    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = this.performInitialization();

    try {
      await this.initializationPromise;
    } finally {
      this.initializationPromise = null;
    }
  }

  private async performInitialization(): Promise<void> {
    try {
      await firstValueFrom(from(this.authService.initialize()));

      // Handle redirect first

      try {
        const result = await firstValueFrom(this.authService.handleRedirectObservable());

        if (result) {
          this.authService.instance.setActiveAccount(result.account);
        }
      } catch (redirectError) {
        console.log('Error handling redirect:', redirectError);
      }

      // Get accounts after potential redirect
      const accounts = this.authService.instance.getAllAccounts();

      // If we have accounts but no active account, set the first one
      if (accounts.length > 0 && !this.authService.instance.getActiveAccount()) {
        this.authService.instance.setActiveAccount(accounts[0]);
      }

      const activeAccount = this.authService.instance.getActiveAccount();

      if (activeAccount) {
        await this.handleAuthenticatedState();
        this.initialized = true;
      } else {
        await firstValueFrom(
          this.authService.loginRedirect({
            scopes: ['https://VelaSuiteTest.onmicrosoft.com/tasks-api/ReadAccess', 'openid'],
          })
        );
      }
    } catch (error) {
      console.error('Error in initialization:', error);
      if (error instanceof BrowserAuthError && error.errorCode === 'interaction_in_progress') {
        console.log('Interaction in progress, waiting for completion');
        return;
      }
      throw error;
    }
  }

  private async handleAuthenticatedState(): Promise<void> {
    const account = this.authService.instance.getActiveAccount();

    if (!account) {
      return;
    }

    try {
      const silentRequest: SilentRequest = {
        scopes: ['https://VelaSuiteTest.onmicrosoft.com/tasks-api/ReadAccess', 'openid'],
        account: account,
      };

      await firstValueFrom(from(this.authService.acquireTokenSilent(silentRequest)));
      const userProfile = await firstValueFrom(this.userService.setUserProfile());
      if (userProfile?.language) {
        await firstValueFrom(this.translate.use(userProfile.language));
      }
    } catch (error) {
      this.authService.loginRedirect();
    }
  }
}
