import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

class CustomLanguageLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private resources: { prefix: string; suffix: string }[] = [
      {
        prefix: '/assets/i18n/',
        suffix: '.json',
      },
    ]
  ) {}

  getTranslation(lang: string): any {
    return forkJoin(
      this.resources.map(config => {
        return this.http.get(`${config.prefix}${lang}${config.suffix}`);
      })
    ).pipe(
      map(response => {
        return response.reduce((a: Object, b: Object) => {
          return Object.assign(a, b);
        });
      })
    );
  }
}

export const setupTranslations = (http: HttpClient) => {
  return new CustomLanguageLoader(http, [{ prefix: './assets/i18n/', suffix: '.json' }]);
};
