import { Component, NgZone, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { WaitingLayerComponent } from './shared/components/waiting-layer/waiting-layer.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AppLoaderService } from './app-loader.service';
import { initializeIcons } from './core/config/icons.config';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [WaitingLayerComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
  title = 'Velasuite';
  isLoaded = false;
  private initializationComplete = false;

  readonly #appLoader = inject(AppLoaderService);
  readonly #ngZone = inject(NgZone);
  readonly #authService = inject(MsalService);

  constructor() {
    this.initializeSyncOperations();
  }

  private initializeSyncOperations(): void {
    registerLocaleData(localeIt);
    ModuleRegistry.registerModules([ClientSideRowModelModule]);
  }

  ngOnInit(): void {
    if (this.initializationComplete) {
      return;
    }

    this.#authService.initialize().subscribe({
      next: () => {
        this.#authService.handleRedirectObservable().subscribe({
          next: (result: AuthenticationResult) => {
            this.initializeApp();
          },
          error: error => {
            console.error('MSAL Redirect Error: ', error);
            window.location.href = '/error';
          },
          complete: () => {
            this.initializeApp();
          },
        });
      },
      error: error => {
        console.error('MSAL Initialization Error: ', error);
        window.location.href = '/error';
      },
    });
  }

  private async initializeApp(): Promise<void> {
    if (this.initializationComplete) {
      return;
    }

    try {
      const accounts = this.#authService.instance.getAllAccounts();
      if (accounts.length > 0) {
        this.#authService.instance.setActiveAccount(accounts[0]);
      }

      this.initializationComplete = true;
      await this.#appLoader.initializeApp();

      this.#ngZone.runOutsideAngular(() => {
        library.add(initializeIcons());
        dom.watch();
      });

      this.#ngZone.run(() => {
        this.isLoaded = true;
      });
    } catch (error) {
      console.error('App initialization failed:', error);
      window.location.href = '/error';
    }
  }
}
